import React from 'react';
import classSet from 'react-classset';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const FormCols = props => {
  const { gutter, breakAt } = props;
  const classes = {
    'form-cols': true,
    [props.className]: props.className,
  };

  const children = props.children?.length ? props.children : [props.children];

  const colTotal = children?.filter(Boolean)?.length;

  return (
    <StyledFormCols
      className={classSet(classes)}
      colTotal={colTotal}
      gutter={gutter}
      breakAt={breakAt}
    >
      {children.filter(Boolean)?.map((el, i) => (
        <div className={`form-col form-col-${i}`} key={i}>
          {el}
        </div>
      ))}
    </StyledFormCols>
  );
};

const StyledFormCols = styled.div.attrs(
  ({ breakAt, gutter, colTotal = 1 }) => ({
    breakAt,
    gutter,
    colTotal,
  }),
)`
  .form-col {
    margin-right: 0;
    margin-bottom: 5px;
  }
  @media only screen and (min-width: ${({ breakAt }) => breakAt}px) {
    &.form-cols {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
    }

    .form-col {
      align-self: stretch;
      flex: 1 1 auto;
      box-sizing: border-box;
      padding-right: ${({ gutter }) => gutter / 2}px;
      padding-left: ${({ gutter }) => gutter / 2}px;
      max-width: ${({ colTotal }) => 100 / colTotal + '%'};
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
`;

FormCols.propTypes = {
  /** window width in px to break row to single col */
  breakAt: PropTypes.number,
  /** space between columns in px */
  gutter: PropTypes.number,
};

FormCols.defaultProps = {
  breakAt: 520,
  gutter: 5,
};
