import React from 'react';
import {
  hasProductCode,
  getProducts,
  ProductCodeFilter,
} from 'util/hasProductCode';
import { useProductCollectionSlugs } from './useProductCollectionSlugs';
import { GetAssetsDocument, GetAssetsQuery } from '__generated__/graphql';
import { useSuspenseQuery } from '@apollo/client';

export interface AssetAccessContextValue extends CEBProductFlags {
  setAssets?: (assets: GetAssetsQuery['assets'] | null) => void;
  hasAccess?: (productCode: ProductCodeFilter) => boolean;
  assets?: GetAssetsQuery['assets'];
  availableProductSlugs?: string[] | null;
}

export const AssetAccessContext = React.createContext<AssetAccessContextValue>(
  {},
);

const practitioner14DayTrialCode = '00ftpr0000';
const practitioner7DayTrialCode = '00ftpr0007';
const secondarySourcesTrialCode = '00ftop0000';
const practitionerAllAccessCode = '13plxx0000';
const secondarySourcesAllAccessCode = '12mall0000';

function useAssets() {
  const [overideAssets, setAssets] = React.useState<
    GetAssetsQuery['assets'] | null
  >(null);

  const { data: assetsData } = useSuspenseQuery(GetAssetsDocument, {
    fetchPolicy: 'network-only',
  });

  const availableProductSlugs = useProductCollectionSlugs(
    assetsData?.assets!,
    /^13/,
  );

  const assets = React.useMemo(
    () => overideAssets || assetsData?.assets,
    [overideAssets, assetsData],
  );

  return React.useMemo(() => {
    return { assets, availableProductSlugs, setAssets };
  }, [assets, availableProductSlugs]);
}

export interface CEBProductFlags {
  hasCitatorAccess?: boolean;
  hasPractitionerTrial?: boolean;
  hasSecondarySourcesTrial?: boolean;
  hasPractitionerAllAccess?: boolean;
  hasSecondarySourcesAllAccess?: boolean;
  hasPractitionerVisible?: boolean;
  hasOnlawBeta?: boolean;
  hasSecondarySourcesProducts?: boolean;
  hasOnlawProducts?: boolean;
  hasCommunityProduct?: boolean;
  trialProduct?: NonNullable<GetAssetsQuery['assets']>[number];
  hasPracticeAreas?: boolean;
  hasSecondarySources?: boolean;
  hasLocalRulesOfCourt?: boolean;
  hasFormsAndMatters?: boolean;
  hasProductCode?: (regex: RegExp) => boolean;
}

function useProductFlags(
  assets: NonNullable<GetAssetsQuery['assets']>,
): CEBProductFlags | undefined {
  return React.useMemo(() => {
    const hasCitatorTrial =
      hasProductCode(assets, practitioner14DayTrialCode) ||
      hasProductCode(assets, practitioner7DayTrialCode) ||
      hasProductCode(assets, secondarySourcesTrialCode);
    const hasPractitionerTrial =
      hasProductCode(assets, practitioner14DayTrialCode) ||
      hasProductCode(assets, practitioner7DayTrialCode);
    const hasSecondarySourcesTrial =
      hasPractitionerTrial || hasProductCode(assets, secondarySourcesTrialCode);
    const hasPractitionerAllAccess = hasProductCode(
      assets,
      practitionerAllAccessCode,
    );
    const hasSecondarySourcesAllAccess = hasProductCode(
      assets,
      secondarySourcesAllAccessCode,
    );
    const hasPractitionerVisible =
      hasProductCode(assets, /^13/) ||
      hasProductCode(assets, practitioner14DayTrialCode, false) ||
      hasProductCode(assets, practitioner7DayTrialCode, false);
    const hasOnlawBeta = hasProductCode(assets, 'FEATUREFLAG-ONLAWBETA');
    const hasSecondarySourcesProducts = hasProductCode(assets, /^15.+/);
    const hasOnlawProducts = hasProductCode(assets, /^89/);
    const hasCitatorAccess =
      hasProductCode(assets, '11plcx0000') || hasCitatorTrial;
    const hasCommunityProduct =
      hasProductCode(assets, '01cepi0024') ||
      hasProductCode(assets, '01epix0024') ||
      hasProductCode(assets, '55ppxx0000') ||
      hasProductCode(assets, '01rpic2300') ||
      hasProductCode(assets, '02ucxx0000');
    const hasLocalRulesOfCourt = hasProductCode(assets, '16lrcx0000');
    const hasFormsAndMatters = hasProductCode(assets, '95tffl0000');

    return {
      hasCitatorAccess,
      hasPractitionerTrial,
      hasSecondarySourcesTrial,
      hasPractitionerAllAccess,
      hasSecondarySourcesAllAccess,
      hasPractitionerVisible,
      hasOnlawBeta,
      hasSecondarySourcesProducts,
      hasOnlawProducts,
      hasCommunityProduct,
      trialProduct:
        getProducts(assets, '00ftpr0000', false)[0] ||
        getProducts(assets, '00ftpr0007', false)[0] ||
        getProducts(assets, '00ftop0000', false)[0],
      hasPracticeAreas: hasProductCode(assets, /^13.+/),
      hasSecondarySources:
        hasSecondarySourcesProducts || hasSecondarySourcesTrial,
      hasProductCode: regex => hasProductCode(assets, regex),
      hasLocalRulesOfCourt,
      hasFormsAndMatters,
    };
  }, [assets]);
}

interface AssetsForRouteProps {
  children?: React.ReactNode;
}

function AssetsForRoute(props: AssetsForRouteProps) {
  const { assets, availableProductSlugs, setAssets } = useAssets();

  const hasAccess = React.useCallback(
    (productCode: ProductCodeFilter) => {
      return productCode ? hasProductCode(assets!, productCode, false) : false;
    },
    [assets],
  );

  const productFlags = useProductFlags(assets!);

  return (
    <AssetAccessContext.Provider
      value={{
        assets,
        setAssets,
        hasAccess,
        ...productFlags,
        availableProductSlugs,
      }}
    >
      {props.children}
    </AssetAccessContext.Provider>
  );
}

export interface AssetAccessProps {
  children: React.ReactNode;
}

export default function AssetAccess(props: AssetAccessProps) {
  return <AssetsForRoute>{props.children}</AssetsForRoute>;
}
