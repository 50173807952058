import React from 'react';
import bugsnag from '@bugsnag/browser';
import bugsnagReact from '@bugsnag/plugin-react';
import { BUGSNAG_API_KEY, BUGSNAG_RELEASE_STAGE } from 'app-constants';

export const bugsnagClient =
  BUGSNAG_API_KEY &&
  bugsnag({
    apiKey: BUGSNAG_API_KEY,
    releaseStage: BUGSNAG_RELEASE_STAGE || 'localhost',
    notifyReleaseStages: ['production', 'staging'],
  });

bugsnagClient && bugsnagClient.use(bugsnagReact, React);
