import React from 'react';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import values from 'lodash/fp/values';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';
export { FormCheckbox } from './form_checkbox';
export { FormButton } from './form_button';
export { FormInput } from './form_input';
export { FormCols } from './form_cols';
export { FormSelect } from './form_select';

/**
 * JsonForm Stateless Component
 */

const isValid = flow(
  values,
  filter(v => v === false),
  isEmpty,
);
export const formContext = React.createContext();

export const JsonForm = ({ onSubmit, onChange, onValidChange, ...props }) => {
  const [formInputs, setInputs] = React.useState({});

  const valid = isValid(formInputs);

  const setFormState = React.useCallback((name, valid) => {
    const inputs = Object.assign(formInputs, { [name]: valid });
    const formValid = isValid(inputs);
    onValidChange && onValidChange(formValid);
    setInputs(inputs);
  });

  const changeHandler = React.useCallback(event => {
    const val = serialize(event.currentTarget, { hash: true });
    onChange && onChange(val);
  });

  const submitHandler = React.useCallback(event => {
    event.preventDefault();
    onSubmit && onSubmit(serialize(event.target, { hash: true }), event);
  });

  return (
    <formContext.Provider value={{ valid, setFormState }}>
      <form {...props} onSubmit={submitHandler} onChange={changeHandler}>
        {props.children}
      </form>
    </formContext.Provider>
  );
};

JsonForm.propTypes = {
  /** called when the form is submitted with the form values as a JSON object */
  onSubmit: PropTypes.func,
  /** called when the form changes with the form values as a JSON object */
  onChange: PropTypes.func,
};

JsonForm.defaultProps = {
  // eslint-disable-next-line no-console
  onSubmit: console.log,
};
